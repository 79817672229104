<template>
    <b-row>
        <b-col cols="12">
            <b-card no-body class="p-1">
                <b-overlay
                        :show="state.loading"
                        rounded="sm"
                >
                    <b-row>
                        <b-col cols="12" md="6">
                            <b-table
                                    ref="refUserListTable"
                                    :items="items"
                                    :small="true"
                                    responsive
                                    :fields="columns"
                                    primary-key="id"
                                    show-empty
                                    empty-text="اطلاعاتی یافت نشد"
                                    class="text-nowrap"
                                    style="min-height : 235px"
                            >
                                <template #cell(coin)="data">
                                    <b-media vertical-align="center">
                                        <template #aside>
                                            <img :src="data.item.coin.logo"
                                                 alt="logo"
                                                 height="25px"
                                                 width="25px"
                                            >
                                        </template>
                                        <router-link
                                                class="font-weight-bold d-block text-nowrap"
                                                :to="{ name: 'statistics', params: { id: data.item.coin.code + 'TOMAN' } }"
                                        >
                                            {{ data.item.coin.label }}
                                        </router-link>
                                        <!--                                        <small class="text-muted">{{ $coinUnit[data.item.coin] }}</small>-->
                                    </b-media>
                                </template>

                                <template #cell(gain)="data">
                        <span dir="ltr">
                            {{ data.item.gain ? $toLocal(data.item.gain) : 0 }}
                        </span>
                                </template>

                            </b-table>
                        </b-col>
                        <b-col cols="12" md="6">
                            <b-table
                                    ref="refUserListTable"
                                    :items="items1"
                                    :small="true"
                                    responsive
                                    :fields="columns"
                                    primary-key="id"
                                    show-empty
                                    empty-text="اطلاعاتی یافت نشد"
                                    class="text-nowrap"
                                    style="min-height : 235px"
                            >
                                <template #cell(coin)="data">
                                    <b-media vertical-align="center">
                                        <template #aside>
                                            <img :src="data.item.coin.logo"
                                                 alt="logo"
                                                 height="25px"
                                                 width="25px"
                                            >
                                        </template>
                                        <b-link
                                                class="font-weight-bold d-block text-nowrap"
                                        >
                                            {{ data.item.coin.label }}
                                        </b-link>
                                        <!--                                        <small class="text-muted">{{ $coinUnit[data.item.coin] }}</small>-->
                                    </b-media>
                                </template>

                                <template #cell(gain)="data">
                                    <span dir="ltr">
                                        {{ data.item.gain ? $toLocal(data.item.gain) : 0 }}
                                    </span>
                                </template>

                            </b-table>
                        </b-col>
                    </b-row>
                </b-overlay>
            </b-card>
        </b-col>
        <b-col cols="12" v-if="false">
            <b-row>
                <b-col lg="8">
                    <fee-line-chart :chart="volChart" :sum.sync="sum"/>
                </b-col>
                <b-col lg="4">
                    <b-overlay
                            :show="loading"
                            rounded="sm"
                    >
                        <fee-filter-chart
                                :date-param.sync="dateParam"
                                :sum="sum"
                                :related-coin.sync="relatedCoin"
                                :coins="[...items,...items1]"
                                @change="getChartData"
                        />
                    </b-overlay>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>

import {
    BCard,
    BTable,
    BMedia,
    BOverlay,
    BLink,
    BRow,
    BCol,
} from 'bootstrap-vue'
import FeeLineChart from "@/views/Accounting/Fees/feeLineChart";
import FeeFilterChart from "@/views/Accounting/Fees/feeFilterChart";
// import NotAllowed from "@/layouts/components/NotAllowed";

export default {
    name: 'Wallets',
    components: {
        FeeFilterChart,
        FeeLineChart,
        BCard,
        BTable,
        BMedia,
        BOverlay,
        BLink,
        BRow,
        BCol,
    },
    data: () => ({
        dir: false,
        loading: false,
        coins: [],
        items: [],
        items1: [],
        columns: [
            {
                label: 'رمز ارز',
                key: 'coin',
                sortable: true,
                searchType: 'text'
            },
            {
                label: 'سود حاصله',
                key: 'gain',
                sortable: true,
                searchType: 'number'
            },
        ],
        volChart: '',
        sum: 0,
        duration: 'MONTHLY',
        number: 0,
        relatedCoin: 'BITCOIN',
        dateParam: {
            from: '2021-01-01T00:00:00',
            to: '2021-01-01T00:00:00'
        },
        type: false
    }),
    methods: {
        async getData() {
            this.state.loading = true

            const res = await this.$axios.get('/markets/gain')

            console.log('data', res.data.data)

            this.items = res.data.data.slice(0, Math.ceil(res.data.data.length / 2))
            this.items1 = res.data.data.slice(Math.ceil(res.data.data.length / 2))
        },

        // async getChartData() {
        //     this.loading = true
        //     console.log('kjhskajdh')
        //
        //     const res = await this.$axios('/trades/fees', {
        //         params: {
        //             relatedCoin: this.relatedCoin,
        //             ...this.dateParam
        //         }
        //     }).catch(() => {
        //         this.loading = false
        //     })
        //     this.volChart = res.data.content
        //     this.loading = false
        // },
    },
    async mounted() {
        await this.getData()
        // await this.getChartData()
    }
}
</script>
<style lang="scss">
[v-cloak] {
  opacity: 0;
}

[dir="rtl"] .test-badge {
  font-size: 10px;
  top: -6px;
  left: -5px !important;
  min-width: 16px;
  min-height: 16px;
}
</style>
